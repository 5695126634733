import { useContext, useEffect, useState } from "react";
import "../styles/minesweeper.scss";
import { Context } from "./Store";
import { IPlayingFieldData, IPlayingFieldDataItem } from "./interfaces";
import axios from "axios";
import { apiServer } from "./common";

const Minesweeper = () => {
    const { storeState, storeDispatch } = useContext(Context);
    const [playingFieldBlocks, setPlayingFieldBlocks] = useState<JSX.Element[]>([]);
    const [timer, setTimer] = useState<NodeJS.Timeout | null | number>(null);
    const [name, setName] = useState("");

    // effect for setting size of playing field
    useEffect(() => {
        document.documentElement.style.setProperty("--size", (storeState.playingFieldSizeCSS / 10) + "fr");
    }, [storeState.playingFieldSizeCSS]);

    // effect for setting number of columns
    useEffect(() => {
        document.documentElement.style.setProperty("--width", (storeState.width + ""));
    }, [storeState.width]);

    //* method for setting number in display
    const setDisplayNumber = (displayName: string, numberToDisplay: number) => {
        const numberForDisplay = numberToDisplay;
        const ones = numberForDisplay % 10;
        const tens = (numberForDisplay % 100 - ones) / 10;
        const hundreds = (numberForDisplay < 0) ? "-" : (numberForDisplay % 1000 - ones - tens * 10) / 100;

        const display = document.getElementById(displayName)!.querySelectorAll("img");

        display[2].src = "/images/numbers/" + Math.abs(ones) + ".png";
        display[1].src = "/images/numbers/" + Math.abs(tens) + ".png";
        display[0].src = "/images/numbers/" + hundreds + ".png";
    }

    //* method for checking if game has ended by flags
    const checkGameHasEndedByFlags = () => {
        let allFlagsAreSet = true;
        storeState.playingFieldData.forEach(blockData => {
            if (blockData.hasMine && !blockData.hasFlag) {
                allFlagsAreSet = false;
                return;
            }
        });

        // game is won
        if (allFlagsAreSet) {
            storeDispatch({
                type: "SET_GAME_IS_IN_PROGRESS",
                payload: false
            });
            storeDispatch({
                type: "SET_GAME_IS_READY_TO_BE_PLAYED",
                payload: false
            });
            storeDispatch({
                type: "SET_GAME_IS_FINISHED",
                payload: true
            });
        }
    }

    //* method for checking if game has ended by opening
    const checkGameHasEndedByOpening = () => {
        storeState.playingFieldData.forEach(blockData => {
            if (blockData.hasMine && !blockData.isOpened) {
                return;
            }
        });
        // game is won
        storeDispatch({
            type: "SET_GAME_IS_IN_PROGRESS",
            payload: false
        });
        storeDispatch({
            type: "SET_GAME_IS_READY_TO_BE_PLAYED",
            payload: false
        });
        storeDispatch({
            type: "SET_GAME_IS_FINISHED",
            payload: true
        });
    }

    //* effect when game is ready to be played/ended
    useEffect(() => {
        // game has started
        if (storeState.gameIsReadyToBePlayed) {
            // playingField pointer events are enabled
            (document.getElementsByClassName("playingField")[0] as HTMLElement).style.pointerEvents = "unset";
        } else {  // game has ended
            // playingField pointer events are disabled
            (document.getElementsByClassName("playingField")[0] as HTMLElement).style.pointerEvents = "none";

            // smiley is dead
            if (storeState.gameIsFinished) {
                (document.getElementById("smiley")! as HTMLImageElement).src = "/images/smiley/sunglasses.png";
            } else {
                (document.getElementById("smiley")! as HTMLImageElement).src = "/images/smiley/dead.png";
            }
            

            // reveal mines
            storeState.playingFieldData.forEach(blockData => {
                if (blockData.hasMine && !blockData.hasRedMine && !blockData.hasFlag) {
                    const blockElement = document.querySelector('[x="' + blockData.x + '"][y= "' + blockData.y + '"]');
                    (blockElement! as HTMLImageElement).src = "/images/playingField/mine.png";
                }

                if (!blockData.hasMine && blockData.hasFlag) {
                    const blockElement = document.querySelector('[x="' + blockData.x + '"][y= "' + blockData.y + '"]');
                    (blockElement! as HTMLImageElement).src = "/images/playingField/mine_X.png";
                }

                if (storeState.gameIsFinished && blockData.hasMine && !blockData.hasFlag) {
                    const blockElement = document.querySelector('[x="' + blockData.x + '"][y= "' + blockData.y + '"]');
                    (blockElement! as HTMLImageElement).src = "/images/playingField/flag.png";
                }
            });
        }
    }, [storeState.gameIsReadyToBePlayed, storeState.playingFieldData]);

    //* effect for rendering blocks when playingFieldData changes
    useEffect(() => {
        // render blocks
        const blocks = [];

        for (let y = 0; y < storeState.height; y++) {
            for (let x = 0; x < storeState.width; x++) {
                const blockIndex = y * storeState.width + x;
                blocks.push(
                    <MinesweeperBlock 
                        key={ blockIndex }
                        x={ x }
                        y={ y }
                        blockData={ storeState.playingFieldData[blockIndex] }
                    />
                );
            }
        }

        setPlayingFieldBlocks([...blocks]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeState.playingFieldData, storeState.flagCount, storeState.gameIsInProgress, storeState.width, storeState.height]);

    //* effect for setting flag number and checking if game is ended
    useEffect(() => {
        const flagNumber = storeState.mines - storeState.flagCount;
        setDisplayNumber("flagDisplay", flagNumber);

        // if all flags were set
        if (flagNumber === 0) {
            checkGameHasEndedByFlags();
        }
    // eslint-disable-next-line
    }, [storeState.mines, storeState.flagCount]);

    //* effect for setting timer number
    useEffect(() => {
        setDisplayNumber("timeDisplay", storeState.timeCount);
    }, [storeState.timeCount]);

    //* effect for starting game timer
    useEffect(() => {
        if (storeState.gameIsInProgress) {
            setTimer(setInterval(() => {
                storeDispatch({
                    type: "ADD_TIME_COUNT",
                    payload: null
                });
            }, 1000));
        } else {
            clearInterval(timer as number);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeState.gameIsInProgress]);

    //* effect for checking if game has ended by opening all blocks except mines
    useEffect(() => {
        if (storeState.openedBlocksCount === storeState.width * storeState.height - storeState.mines) {
            checkGameHasEndedByOpening();
        }
    }, [storeState.openedBlocksCount]);

    //* effect for showing score submit
    useEffect(() => {
        if (storeState.gameIsFinished) {
            storeDispatch({
                type: "SET_SHOW_SUBMIT_SCORE",
                payload: true
            });
        }
        
    }, [storeState.gameIsFinished, storeState.gameIsReadyToBePlayed]);

    return (
        <div className="minesweeper"
        onContextMenu={event => {
            event.preventDefault();
        }}>
            <div className="gameContainer">
                <div className="gameInformation">
                    <Display displayId={"flagDisplay"} description="Mine display number" />
                    <Smiley />
                    <Display displayId={"timeDisplay"} description="Time display number" />
                </div>

                <div className="playingField"
                    onMouseDown={event => {
                        if (event.buttons === 1) {
                            (document.getElementById("smiley")! as HTMLImageElement).src = "/images/smiley/surprised.png";
                        }
                    }}

                    onMouseUp={() => {
                        (document.getElementById("smiley")! as HTMLImageElement).src = "/images/smiley/outdent.png";
                    }}

                    onMouseLeave={event => {
                        if (event.buttons === 1) {
                            (document.getElementById("smiley")! as HTMLImageElement).src = "/images/smiley/outdent.png";
                        }
                    }}

                    onMouseEnter={event => {
                        if (event.buttons === 1) {
                            (document.getElementById("smiley")! as HTMLImageElement).src = "/images/smiley/surprised.png";
                        }
                    }}
                >
                    {playingFieldBlocks.map(block => {
                        return (block);
                    })}
                </div>
            </div>
            {storeState.showSubmitScore && <div className="scoreSubmit">
                
                <h2>Submit your score</h2>
                <input type="text"
                    value={name}
                    onChange={event => setName(event.target.value)}
                    placeholder="Enter your name"
                />
                <div className="buttonContainer">
                    <button onClick={() => {
                        axios.post(`${apiServer}/score`, {
                            time: storeState.timeCount,
                            type: (storeState.selectedType).charAt(0),
                            name: name
                        }).then(() => {
                            storeDispatch({
                                type: "SET_SHOW_SUBMIT_SCORE",
                                payload: false
                            });
                        });
                    }}>
                        Submit score
                    </button>
                    <button onClick={() => {
                        storeDispatch({
                            type: "SET_SHOW_SUBMIT_SCORE",
                            payload: false
                        });
                    }}>
                        Don't submit
                    </button>
                </div>
            
            </div>}
        </div>
    );
}

interface IMinesweeperBlockProps {
    x: number,
    y: number,
    blockData: IPlayingFieldDataItem
}

//* Single block element from playingField
const MinesweeperBlock = ({ x, y, blockData }: IMinesweeperBlockProps) => {
    const { storeState, storeDispatch } = useContext(Context);

    //* function seeds mines, calculates numbers on blocks
    function preparePlayingField(pressedBlock: HTMLElement) {
        // extract x and y from pressedBlock
        const pressedBlockX = parseInt(pressedBlock.getAttribute("x") as string);
        const pressedBlockY = parseInt(pressedBlock.getAttribute("y") as string);

        // new empty array
        let playingFieldDataLocal: IPlayingFieldData = [];
        storeDispatch({
            type: "SET_PLAYING_FIELD_DATA",
            payload: []
        });

        // seeding mines
        const mineArrayLength = storeState.width * storeState.height;
        const mineArray = new Array(mineArrayLength).fill(false);

        // pressedBlock is ignored (set as if it has mine, this will be cleared later)
        mineArray[pressedBlockY * storeState.width + pressedBlockX] = true;

        let mineCountLocal = storeState.mines;
        // assigning mines to random indexes
        while (mineCountLocal > 0) {
            const mineIndex = Math.floor(Math.random() * mineArrayLength);
            if (!mineArray[mineIndex]) {
                mineArray[mineIndex] = true;
                mineCountLocal--;
            }
        }

        // pressedBlock is set back, to not have mine
        mineArray[pressedBlockY * storeState.width + pressedBlockX] = false;

        // preparing playing field
        for (let y = 0; y < storeState.height; y++) {
            for (let x = 0; x < storeState.width; x++) {
                const hasMine = mineArray[y * storeState.width + x];
                playingFieldDataLocal = [...playingFieldDataLocal, {
                    x: x, 
                    y: y, 
                    hasMine: (hasMine),
                    hasRedMine: false,
                    number: 0, 
                    hasFlag: false, 
                    isOpened: false,
                    adjacentBlocks: []
                }];
            }
        }

        // setting numbers on other blocks
        playingFieldDataLocal.forEach(block => {
            
            // check if mine is on edge
            let minX = (block.x === 0) ? block.x : block.x - 1;
            let maxX = (block.x === storeState.width - 1) ? block.x: block.x + 1;
            let minY = (block.y === 0) ? block.y : block.y - 1;
            let maxY = (block.y === storeState.height - 1) ? block.y : block.y + 1;
            
            const adjacentBlocks: Array<IPlayingFieldDataItem> = [];

            // increase number for adjacent blocks
            for (let x = minX; x <= maxX; x++) {
                for (let y = minY; y <= maxY; y++) {
                    if (block.x !== x || block.y !== y) {
                        const adjacentBlock: IPlayingFieldDataItem = playingFieldDataLocal.find(block => block.x === x && block.y === y)!;
                        adjacentBlocks.push(adjacentBlock);
                        
                        if (block.hasMine) {
                            adjacentBlock!.number++;
                        }
                    }
                }
            }
            block.adjacentBlocks = adjacentBlocks;
        });
        
        storeDispatch({
            type: "SET_PLAYING_FIELD_DATA",
            payload: playingFieldDataLocal
        });
        storeDispatch({
            type: "SET_GAME_IS_IN_PROGRESS",
            payload: true
        });

        return playingFieldDataLocal[pressedBlockY * storeState.width + pressedBlockX];
    }

    const openBlocks = (blockData: IPlayingFieldDataItem, blockElement: HTMLImageElement) => {
        if (!blockData.isOpened) {
            storeDispatch({
                type: "ADD_OPEN_BLOCKS_COUNT",
                payload: null
            });
        }

        blockData.isOpened = true;
        blockElement.src = "/images/playingField/" + blockData.number + ".png";

        blockData.adjacentBlocks.forEach(adjacentBlockData => {
            const adjacentBlockElement: HTMLImageElement = document.querySelector('[x="' + adjacentBlockData.x + '"][y= "' + adjacentBlockData.y + '"]')!;

            if(!adjacentBlockData.isOpened && !blockData.hasMine && blockData.number === 0) {
                openBlocks(adjacentBlockData, adjacentBlockElement);
            }
        });
    }
    
    return (
        <img 
            onMouseUp={event => {
                if (!storeState.gameIsInProgress) {
                    const firstOpenedBlockData = preparePlayingField(event.target as HTMLElement);
                    openBlocks(firstOpenedBlockData, event.target as HTMLImageElement);
                }
                else {
                    // right click (for flag)
                    if (event.button === 2 && !blockData.isOpened) {
                        if (!blockData.hasFlag) {
                            (event.target as HTMLImageElement).src = "/images/playingField/flag.png";
                            blockData.hasFlag = true;
                            storeDispatch({
                                type: "SET_FLAG_COUNT",
                                payload: storeState.flagCount + 1
                            });
                        } else {
                            (event.target as HTMLImageElement).src = "/images/playingField/block.png";
                            blockData.hasFlag = false;
                            storeDispatch({
                                type: "SET_FLAG_COUNT",
                                payload: storeState.flagCount - 1
                            });
                        }
                        
                        
                    // left click (for block open)
                    } else if (event.button === 0) {
                        // block has a mine -> game is ended
                        if (blockData.hasMine) {
                            (event.target as HTMLImageElement).src = "/images/playingField/mine_red.png";
                            blockData.hasRedMine = true;
                            storeDispatch({
                                type: "SET_GAME_IS_IN_PROGRESS",
                                payload: false
                            });
                            storeDispatch({
                                type: "SET_GAME_IS_READY_TO_BE_PLAYED",
                                payload: false
                            });
                        } else {
                            openBlocks(blockData, event.target as HTMLImageElement);
                        }
                    }
                }
            }}

            onContextMenu={event => {
                event.preventDefault();
            }}

            onMouseDown={event => {
                if (!storeState.gameIsFinished && event.buttons === 1 && (blockData === undefined || !blockData.isOpened)) {
                    (event.target as HTMLImageElement).src = "/images/playingField/0.png";
                }
            }}

            onMouseEnter={event => {
                if (!storeState.gameIsFinished && event.buttons === 1 && (blockData === undefined || !blockData.isOpened)) {
                    (event.target as HTMLImageElement).src = "/images/playingField/0.png";
                }
            }}

            onMouseLeave={event => {
                if (!storeState.gameIsFinished && event.buttons === 1 && (blockData === undefined || !blockData.isOpened)) {
                    (event.target as HTMLImageElement).src = "/images/playingField/block.png";
                }
            }}
        //@ts-ignore
        x={x} y={y} src="/images/playingField/block.png" alt="Block" />
    );
}

//* Smiley block
const Smiley = () => {
    const { storeState, storeDispatch } = useContext(Context);

    return (
        <div className="smileyContainer">
            <img
                id="smiley"
                src="/images/smiley/outdent.png"
                alt="Smiley"
                
                onMouseDown={event => {
                    (event.target as HTMLImageElement).src = "/images/smiley/indent.png";
                }}

                onMouseUp={event => {
                    (event.target as HTMLImageElement).src = "/images/smiley/outdent.png";
                    // reset block look
                    const playingFieldBlocks = document.querySelector("[class=playingField]")!.querySelectorAll("img");
                    playingFieldBlocks.forEach(block => {
                        block.src = "/images/playingField/block.png";
                    });
                    storeDispatch({
                        type: "SET_FLAG_COUNT",
                        payload: 0
                    });
                    storeDispatch({
                        type: "SET_TIME_COUNT",
                        payload: 0
                    });
                    storeDispatch({
                        type: "SET_GAME_IS_READY_TO_BE_PLAYED",
                        payload: true
                    });
                    storeDispatch({
                        type: "SET_GAME_IS_IN_PROGRESS",
                        payload: false
                    });
                    storeDispatch({
                        type: "SET_GAME_IS_FINISHED",
                        payload: false
                    });
                    storeDispatch({
                        type: "SET_OPEN_BLOCKS_COUNT",
                        payload: 0
                    });
                }}

                onMouseLeave={event => {
                    if (storeState.gameIsReadyToBePlayed)
                        (event.target as HTMLImageElement).src = "/images/smiley/outdent.png";
                }}

                onMouseEnter={event => {
                    if (event.buttons === 1) {
                        (event.target as HTMLImageElement).src = "/images/smiley/indent.png";
                    }
                }}
            />
        </div>
    );
}

interface IDisplayProps {
    displayId: string,
    description: string
}

//* Number display for flag count and time
const Display = ({ displayId, description }: IDisplayProps) => {
    return (
        <div id={displayId}>
            <img src="/images/numbers/0.png" alt={description} />
            <img src="/images/numbers/0.png" alt={description} />
            <img src="/images/numbers/0.png" alt={description} />
        </div>
    );
}

export default Minesweeper;