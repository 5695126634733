import { Dispatch, useContext, useEffect, useState } from "react";
import axios from "axios";
import { Context } from "./Store";
import { apiServer } from "./common";
import "../styles/menu.scss";
import "../styles/app.scss";

const Menu = () => {
    const {storeState, storeDispatch} = useContext(Context);
    const [width, setWidth] = useState(storeState.width);
    const [height, setHeight] = useState(storeState.height);
    const [mines, setMines] = useState(storeState.mines);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [selectedType, setSelectedType] = useState(storeState.selectedType);
    const [selectedTypeScoreboard, setSelectedTypeScoreboard] = useState("E");
    const [scoreboard, setScoreboard] = useState([]);

    //* effect for getting scoreboard
    useEffect(() => {
        axios.get(`${apiServer}/score/${selectedTypeScoreboard}`).then(res => {
            setScoreboard(res.data);
        });
    }, [selectedTypeScoreboard, storeState.showSubmitScore]);

    //* effect for displaying parameters of types
    useEffect(() => {
        switch (selectedType) {
            case "Expert": {
                setWidth(30);
                setHeight(16);
                setMines(99);
                break;
            }
            case "Intermediate": {
                setWidth(16);
                setHeight(16);
                setMines(40);
                break;
            }
            case "Beginner": {
                setWidth(9);
                setHeight(9);
                setMines(10);
                break;
            }
        }
    }, [selectedType])

    //* wrapper which adds 0 if input is NaN
    const onChangeWrapper = (setState: Dispatch<number>, value: string) => {
        if (value.length === 0)
            setState(0);
        else {
            setState(parseInt(value));
        }
    }

    //* effect for setting selected type
    useEffect(() => {
        if (width === 30 && height === 16 && mines === 99)
            setSelectedType("Expert");
        else if (width === 16 && height === 16 && mines === 40)
            setSelectedType("Intermediate");
        else if (width === 9 && height === 9 && mines === 10)
            setSelectedType("Beginner");
        else {
            setSelectedType("Custom");
        }
    }, [width, height, mines]);

    // function for +/- button click
    const onResizeClick = (number: number) => {
        if (number === 1 && storeState.playingFieldSizeCSS < 10) {
            storeDispatch({
                type: "INCREMENT_SIZE_CSS",
                payload: null
            });
        }
        else if (5 < storeState.playingFieldSizeCSS) {
            storeDispatch({
                type: "DECREMENT_SIZE_CSS",
                payload: null
            });
        }
    }

    interface score {
        name: string
        time: number
    }

    return (
        <div className="menu">
            <div className="settings">
                <h2>Settings</h2>
                <div className="selection">
                    <div onClick={() => setSelectedType("Expert")}>
                        <input
                            type="radio"
                            checked={selectedType === "Expert"}
                            readOnly
                        />
                        <label>Expert</label>
                    </div>
                    <div onClick={() => setSelectedType("Intermediate")}>
                        <input
                            type="radio"
                            checked={selectedType === "Intermediate"}
                            readOnly
                        />
                        <label>Intermediate</label>
                    </div>
                    <div onClick={() => setSelectedType("Beginner")}>
                        <input
                            type="radio"
                            checked={selectedType === "Beginner"}
                            readOnly
                        />
                        <label>Beginner</label>
                    </div>
                    <div onClick={() => setSelectedType("Custom")}>
                        <input
                            type="radio"
                            checked={selectedType === "Custom"}
                            readOnly
                        />
                        <label>Custom</label>
                    </div>
                </div>
                <div>Width</div>
                <input
                    value={width}
                    onChange={event => onChangeWrapper(setWidth, event.target.value)}
                    type="number"
                />
                <div>Height</div>
                <input
                    value={height}
                    onChange={event => onChangeWrapper(setHeight, event.target.value)}
                    type="number"
                />
                <div>Mines</div>
                <input
                    value={mines}
                    onChange={event => onChangeWrapper(setMines, event.target.value)}
                    type="number"
                />
                <br />
                <div className="buttonContainer">
                    <button onClick={() => {
                        if (mines >= width * height) {
                            setShowErrorMessage(true);
                            return;
                        }
                        storeDispatch({
                            type: "SET_WIDTH",
                            payload: width
                        });
                        storeDispatch({
                            type: "SET_HEIGHT",
                            payload: height
                        });
                        storeDispatch({
                            type: "SET_MINES",
                            payload: mines
                        });
                        storeDispatch({
                            type: "SET_SELECTED_TYPE",
                            payload: selectedType
                        });
                    }}>
                        Set dimensions
                    </button>
                </div>
                {showErrorMessage && "Number of mines is too big for playing field!"}
                
                <div className="displaySettings">
                    <h4>Display settings</h4>
                    <label>Use these buttons if playing field doesn't fit the screen</label>
                    <div className="buttonContainer">
                        <button onClick={() => onResizeClick(1)}>+</button>
                        <button onClick={() => onResizeClick(-1)}>-</button>
                    </div>
                </div>
            </div>
            <div className="leaderboard">
                <h2>Leaderboard</h2>
                <div className="selection">
                    <div onClick={() => setSelectedTypeScoreboard("E")}>Expert</div>
                    <div onClick={() => setSelectedTypeScoreboard("I")}>Intermediate</div>
                    <div onClick={() => setSelectedTypeScoreboard("B")}>Beginner</div>
                </div>
                <div className="scores">
                    <div className="header">
                        <div>Place</div>
                        <div>Time</div>
                        <div>Name</div>
                    </div>
                    {scoreboard.map((score: score, i) => {
                        return (
                            <div key={i}>
                                <div>{i + 1}.</div>
                                <div>{score.time}</div>
                                <div>{score.name}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Menu;