import Menu from "./Menu";
import Minesweeper from "./Minesweeper";
import "../styles/landing.scss";

const Landing = () => {
    return (
        <div className="landing">
            <div className="main">
                <h1>Minesweeper</h1>
                <Minesweeper />
            </div>
            <Menu />
        </div>
    );
}

export default Landing;