import React from 'react';
import ReactDOM from 'react-dom';

import Landing from './components/Landing';
import Store from './components/Store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
    <React.StrictMode>
        <Store>
            <Landing />
        </Store>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorkerRegistration.register();

