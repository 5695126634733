import { createContext, useReducer } from "react";
import { IPlayingFieldData } from "./interfaces";

interface IAction {
    type: string,
    payload: any
}

const reducer = (state: IInitialState, action: IAction) => {
    switch (action.type) {
        case "SET_WIDTH":
            return {
                ...state,
                width: action.payload
            }
        case "SET_HEIGHT":
            return {
                ...state,
                height: action.payload
            };
        case "SET_MINES":
            return {
                ...state,
                mines: action.payload
            };
        case "SET_PLAYING_FIELD_DATA":
            return {
                ...state,
                playingFieldData: action.payload
            };
        case "SET_GAME_IS_IN_PROGRESS":
            return {
                ...state,
                gameIsInProgress: action.payload
            };
        case "SET_GAME_IS_READY_TO_BE_PLAYED":
            return {
                ...state,
                gameIsReadyToBePlayed: action.payload
            };
        case "SET_GAME_IS_FINISHED":
            return {
                ...state,
                gameIsFinished: action.payload
            };
        case "SET_FLAG_COUNT":
            return {
                ...state,
                flagCount: action.payload
            };
        case "SET_TIME_COUNT":
            return {
                ...state,
                timeCount: action.payload
            };
        case "ADD_TIME_COUNT":
            return {
                ...state,
                timeCount: state.timeCount + 1
            };
        case "SET_OPEN_BLOCKS_COUNT":
            return {
                ...state,
                openedBlocksCount: action.payload
            };
        case "ADD_OPEN_BLOCKS_COUNT":
            return {
                ...state,
                openedBlocksCount: state.openedBlocksCount + 1
            };
        case "SET_SELECTED_TYPE":
            return {
                ...state,
                selectedType: action.payload
            };
        case "INCREMENT_SIZE_CSS":
            return {
                ...state,
                playingFieldSizeCSS: state.playingFieldSizeCSS + 1
            };
        case "DECREMENT_SIZE_CSS":
            return {
                ...state,
                playingFieldSizeCSS: state.playingFieldSizeCSS - 1
            };
        case "SET_SHOW_SUBMIT_SCORE":
            return {
                ...state,
                showSubmitScore: action.payload
            };
        default:
            return state;
    }
}

interface IInitialState {
    selectedType: string,
    width: number,
    height: number,
    mines: number,
    gameIsInProgress: boolean,
    gameIsReadyToBePlayed: boolean,
    gameIsFinished: boolean,
    flagCount: number,
    timeCount: number,
    openedBlocksCount: number,
    playingFieldData: IPlayingFieldData,
    playingFieldSizeCSS: number,
    showSubmitScore: boolean,
}

const initialState: IInitialState = {
    selectedType: "Expert",
    width: 30,
    height: 16,
    mines: 99,
    gameIsInProgress: false,
    gameIsReadyToBePlayed: true,
    gameIsFinished: false,
    flagCount: 0,
    timeCount: 0,
    openedBlocksCount: 0,
    playingFieldData: [],
    playingFieldSizeCSS: 7,
    showSubmitScore: false,
}

interface IContextProps {
    storeState: IInitialState,
    storeDispatch: ({type, payload}:{type: string, payload: any}) => void;
}

const Store = ({ children }: any) => {
    const [storeState, storeDispatch] = useReducer(reducer, initialState);

    return (
        <Context.Provider value={{ storeState, storeDispatch }}>
            { children }
        </Context.Provider>
    )
}

export const Context = createContext({} as IContextProps);
export default Store;